@media (min-width: 801px){
    .Download{
        width: 100%;
        display: flex;
        height: 85vh;
        justify-content: center;
        align-items: center;
        position: relative;
    }
    
    .download-area{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        font-size: 26px;
        cursor: pointer;
        font-weight: 600;
        padding: 20px;
        transition: .3s;
    }
    
    .download-area:hover{
        border-radius: 20px;
        box-shadow: 2px 2px 10px black;
    }
}

@media (max-width: 800px){
    .Download{
        width: 100%;
        display: flex;
        height: 85vh;
        justify-content: center;
        align-items: center;
        position: relative;
    }
    
    .download-area{
        margin-top: 30px;
        background-color: rgb(253, 63, 63);
        height: 50px;
        display: flex;
        width: 90%;
        justify-content: center;
        align-items: center;
        font-weight: 600;
        padding: 0px 10px;
        color: rgba(0, 0, 0, 0.753);
        cursor: pointer;
    }

    .download-area div{
        width: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
    }

    .download-area div img{
        width: 80%;
    }
    
}