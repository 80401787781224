.loading{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 20px;
    background-color: rgba(0, 0, 0, 0.753);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.c-loader {
    animation: is-rotating 1s infinite;
    border: 6px solid #e5e5e5;
    border-radius: 50%;
    border-top-color: #51d4db;
    height: 50px;
    width: 50px;
}

@keyframes is-rotating {
    to {
      transform: rotate(1turn);
    }
}