@media (min-width: 801px) {
    .icon {
        width: 50px;
        height: 50px;
        transition: .3s;
    }

    .icon:hover {
        transform: scale(1.1);
        transition: .3s;
    }

    .config-icon {
        width: 40px;
    }

    .user-icon {
        width: 40px;
        border-radius: 50%;
        height: 40px;
    }

    .navbar-prop-style {
        background-color: rgb(1, 5, 27);
    }

    .navbar-prop-styleBLACKMODE {
        background-color: rgb(2, 0, 0);
        border-bottom: 3px solid rgba(255, 255, 255, 0.192);
    }

    .nav-lg {
        margin-right: auto;
        width: 100%;

        display: flex;
        justify-content: end;
        align-items: center;
    }

    .nav-sm {
        display: none !important;
    }


    .navbar-toggle-img{
        display: none;
    }
}

@media (max-width: 800px) {

    .navbar-toggle-img{
        width: 70px;
    }
    .icon {
        width: 50px;
        height: 50px;
        transition: .3s;
    }

    .icon:hover {
        transform: scale(1.1);
        transition: .3s;
    }

    .config-icon {
        width: 40px;
    }

    .user-icon {
        width: 40px;

        height: 40px;
    }

    .navbar-prop-style {
        background-color: rgb(49, 49, 49);
    }

    .nav-lg {
        display: none !important;
    }

    .nav-sm {
        margin-right: auto;
        width: 100%;
        justify-content: end;
        align-items: start;
        font-weight: 600;
    }

    .nav-sm-box{
        text-align: start !important;
        display: flex;
        flex-direction: column !important;
        justify-content: start !important;
        align-items: start !important;
        width: max-content !important;

    }

    .nav-link-sm {
        color: white !important;
        width: 100%;
        display: flex !important;
        justify-content: start;
        align-items: center;
    }



    .icon-box-nav {
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .icon-box-nav img{
        width: 80%;
        height: 80%;
    }

    .exceptionHelp{
        width: 100% !important;
        height: 100% !important;
    }

    .exeptionAsk{
        width: 70% !important;
        height: 70% !important;
    }

    .log-out-btn {
        color: rgb(243, 44, 44) !important;
        background-color: rgba(8, 8, 8, 0.568) !important;
        width: 100%;
        margin: 0;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
    }

}