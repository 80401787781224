.Footer{
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: end;
    padding-bottom: 20px;
    box-sizing: border-box;
}

.Footer a{
    text-decoration: none;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.445);
}

.FooterBLACKMODE{
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: end;
    padding-bottom: 20px;
    box-sizing: border-box;
}

.FooterBLACKMODE a{
    text-decoration: none;
    font-weight: 600;
    color: rgba(255, 255, 255, 0.445);
}