@media (min-width: 801px){
    .NavbarNav{
        box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.24);
        background-color: white !important;

        color: black !important;
    }

    .NavbarNavBLACKMODE{
        box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.13);
        background-color: rgb(4, 11, 75);
        color: white !important;
        border-bottom: 2px solid rgb(0, 0, 0);
    }

    .icon-drop{
        width: 20px;
    }

    .brand-hover{
        cursor: pointer !important;
        color: black !important;
    }

    .brand-hoverBLACKMODE{
        cursor: pointer !important;
        color: white !important;
    }

    .nav-items{
        display: flex !important;
        flex-direction: column !important;
        align-items: center;

        margin-left: auto;
    }

    .BLACKMODE{
        color: white !important;
        width: 100%;
        font-weight: 600;
    }


    .BLACKMODE:hover{
        color: rgb(0, 4, 15) !important;
        background-color: rgba(255, 255, 255, 0.61);
    }

}

@media (max-width: 800px){

    .NavbarNav{
        box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.24);
        background-color: white !important;

        color: black !important;
    }

    .NavbarNavBLACKMODE{
        box-shadow: 3px 3px 4px rgba(5, 49, 245, 0.13);
        background-color: rgb(14, 3, 56);
        color: rgb(241, 238, 238) !important;
    }


    .brand-hoverBLACKMODE{
        cursor: pointer !important;
        color: white !important;
    }
    
    .icon-drop{
        width: 20px;
    }

    .brand-hover{
        cursor: pointer;
    }

    .nav-items{
        margin-left: auto;
    }

    .BLACKMODE{
        color: white !important;
    }
    
}
